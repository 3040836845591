<template>
  <div class="hx-home-page">
    <div
      :class="userInfoShow ? 'not-resiger' : 'is-resiger'"
      class="home-content-one"
    >
      <div class="banner-content">
        <div class="left-banner">
          <p class="first-lable">
            {{ projectEnglishName }}
            {{ translateTitle(projectTitle) }}
          </p>
          <p class="second-lable">
            {{ translateTitle("即刻交易比特币、以太坊等上百种数字资产") }}
          </p>
          <div v-show="!userInfoShow" class="regsiter-content">
            <input
              class="regsiter-input"
              v-model="resiterValue"
              type="text"
              :placeholder="translateTitle(`输入用户名/邮箱/手机号`)"
            />
            <span @click="onResiter(1)" class="goRegsiter">{{
              translateTitle("注册")
            }}</span>
          </div>
          <div v-show="userInfoShow" class="regsiter-content">
            <span @click="onTrade" class="goRegsiter theme-btn">{{
              translateTitle("立即交易")
            }}</span>
          </div>
        </div>
        <div class="home-content-one-right">
          <img
            src="../../assets/img_n/theme/banner-right.png"
            width="440"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 成交量介绍 -->
    <div class="home-content-three">
      <div class="content">
        <div class="point">
          <div class="point-item">
            <span>{{ translateTitle("近24小时成交量") }}</span>
            <p v-if="!isUeasy">{{ translateTitle("400亿美元") }}</p>
            <p v-else>110+</p>
          </div>
          <div class="point-item">
            <span>{{ translateTitle("用户国家分布") }}</span>
            <p>200+</p>
          </div>
          <div class="point-item">
            <span>{{ translateTitle("用户数") }}</span>
            <p>{{ translateTitle("120万") }}+</p>
          </div>
          <div class="point-item">
            <span>{{ translateTitle("交易对") }}</span>
            <p>400+</p>
          </div>
        </div>
      </div>
    </div>
    <div class="tips-massage-content">
      <div class="content">
        <div class="latest-news">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="52"
            viewBox="0 0 130 52"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M110.54 50.718C110.243 51.4903 109.501 52 108.673 52H1.99609C0.891525 52 -0.00390625 51.1046 -0.00390625 50V2C-0.00390625 0.895432 0.891525 0 1.99609 0H127.135C128.538 0 129.505 1.40795 129.002 2.71796L110.54 50.718Z"
              fill="url(#paint0_linear_162_34398)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_162_34398"
                x1="-0.00390625"
                y1="26"
                x2="130.047"
                y2="26"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFD493" />
                <stop offset="0.87" stop-color="#BB8338" />
              </linearGradient>
            </defs>
          </svg>
          <p class="ln-text">{{ translateTitle("Latest News") }}</p>
        </div>
        <div class="right-content-box">
          <div class="left">
            <!-- <i class="iconfont iconxiaoxi"></i> -->
            <a :href="firstArticles.html_url" target="_blank">
              <span class="article-title">
                {{ firstArticles.title || "--" }}</span
              >
            </a>
          </div>
          <div class="left-driver"></div>
          <div class="bottom">
            <a :href="secondArticles.html_url" target="_blank">
              <span class="article-title mxwid">
                {{ secondArticles.title || "--" }}</span
              >
            </a>
          </div>
          <div @click="onClickMore" class="right">
            <span>{{ translateTitle("查看更多") }} →</span>
            <!-- <i class="iconfont icongengduo"></i> -->
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-two" :class="imgList.length == 0 ? '' : ''">
      <div class="swiper-sider-content" v-if="showBanner">
        <div class="swiper-content">
          <div v-if="imgList.length > 4" class="swiper-prev" slot="button-prev">
            <img src="../../assets/img/left-arrow.png" alt="" />
          </div>
          <div class="old-img" v-if="imgList.length <= 4">
            <ul v-for="(item, index) in oldImgList" :key="index">
              <li>
                <span @click="jumpToUrl(item)">
                  <img :src="item.language['img_url_' + language]" alt="" />
                </span>
              </li>
            </ul>
          </div>

          <swiper
            v-else
            :options="swiperOption"
            ref="mySwiper"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-item"
              v-for="(item, index) of imgList"
              :key="index"
            >
              <!-- <span @click="jumpToUrlV2(item)"> -->
              <img
                class="swiper-img"
                :src="item.language['img_url_' + language]"
                :data-href="
                  item.language
                    ? item.language[`url_${language}`]
                    : item.param.url
                "
                alt=""
              />
              <!-- </span> -->
            </swiper-slide>
          </swiper>

          <div v-if="imgList.length > 4" class="swiper-next" slot="button-next">
            <img src="../../assets/img/right-arrow.png" alt="" />
          </div>
        </div>
      </div>
      <!-- <div
        v-if="imgList.length >= 4"
        class="swiper-pagination"
        slot="pagination"
      ></div> -->

      <div class="quotes-content">
        <div class="list-type">
          <div
            v-for="(item, index) in listType"
            :key="index"
            class="tab"
            :class="{ active: item.val === active }"
          >
            <span>{{ translateTitle(item.text) }} </span>
            <i class="type-indicator"></i>
          </div>
        </div>
        <el-table
          v-loading="tableLoading"
          :data="tableDataComputed"
          row-key="symbol_id"
          style="width: 100%"
          class="trade-table"
          @row-click="onClickRow"
          :row-class-name="tableRowClassName"
          header-row-class-name="headTableRow"
        >
          <el-table-column
            :label="translateTitle('交易对')"
            align="left"
            min-width="140"
            prop="from_symbol"
          >
            <template slot-scope="{ row }">
              <span
                class="coin-content"
                @click="onSymbolClick(row)"
                style="cursor: pointer"
              >
                <img class="coin-img" :src="row.coin_url" alt="" />
                <span
                  class="left-symbol"
                  style="margin-left: 10px; margin-right: 5px; font-size: 14px"
                  >{{ row.from_symbol }}</span
                >
                <span class="dark-color"> / {{ row.to_symbol }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            prop="usd"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro left-row"> {{ row.last }} </span>
                <span class="dark-color DINPro"
                  >/ {{ systemCurrencyMaps[systemCurrency]
                  }}{{ row.currency[systemCurrency] }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="center"
            prop="info.price_change_percent"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <span
                class="left-row DINPro"
                :class="Number(row.price_change_percent) > 0 ? 'green' : 'red'"
                ><span
                  class="green"
                  v-show="Number(row.price_change_percent) > 0"
                  style="margin-right: -3px"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('行情')"
            align="center"
            prop="info.high"
            min-width="180"
          >
            <template slot-scope="{ row }">
              <!-- <span class="DINPro">{{ row.info.high }}</span> -->
              <img
                class="mrket-img"
                v-if="Number(row.price_change_percent) > 0"
                src="../../assets/img/markets/markt-up.png"
              />
              <img
                class="mrket-img"
                v-else
                src="../../assets/img/markets/markt-download.png"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('操作')"
            align="center"
            min-width="70"
          >
            <template slot-scope="{ row }">
              <el-button class="operate" @click="onSymbolClick(row)">{{
                translateTitle("交易")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataComputed.length"
          ></no-data>
        </el-table>
        <div class="more-info">
          <a :href="routeSplicing(language, 'markets')" class="more">
            <span class="more-title">{{ translateTitle("查看更多") }} →</span>
            <!-- <i class="iconfont icongengduo"></i> -->
          </a>
        </div>
      </div>
    </div>
    <div class="home-content-n-1">
      <div class="n1-content">
        <div class="n1-left">
          <p class="n1l-title">
            {{ translateTitle("创建个人加密货币投资组合") }}
          </p>
          <p class="n1l-sub-title">
            {{ translateTitle("仅需3步，开启首笔交易") }}
          </p>
          <div class="n1l-item">
            <img src="../../assets/img_n/theme/recharge.png" class="item-img" />
            <div class="item-text">
              <p class="it-title">{{ translateTitle("向账户充值") }}</p>
              <p class="it-content">
                {{
                  translateTitle(
                    "充值加密货币账户，即可开启加密货币交易。 您可选取多种支付方式进行充值"
                  )
                }}
              </p>
            </div>
          </div>
          <div class="n1l-item">
            <img
              src="../../assets/img_n/theme/certified.png"
              class="item-img"
            />
            <div class="item-text">
              <p class="it-title">{{ translateTitle("完成身份验证") }}</p>
              <p class="it-content">
                {{ translateTitle("完成身份验证，确保个人账户和交易安全") }}
              </p>
            </div>
          </div>
          <div class="n1l-item">
            <img src="../../assets/img_n/theme/trade.png" class="item-img" />
            <div class="item-text">
              <p class="it-title">{{ translateTitle("开始交易") }}</p>
              <p class="it-content">
                {{
                  translateTitle(
                    "一切准备就绪！买卖加密货币，设置定投，探索产品与服务"
                  )
                }}
              </p>
            </div>
          </div>
          <a class="begin" :href="routeSplicing(language, 'finances')">{{
            translateTitle("开始使用")
          }}</a>
        </div>
        <div class="n1-right">
          <img src="../../assets/img_n/theme/phone.png" class="n1r-phone" />
        </div>
      </div>
    </div>
    <div class="home-content-n-2">
      <div class="n2-content">
        <p class="n2-title">
          {{ translateTitle("88") }} , {{ translateTitle("探索无限可能") }}
        </p>
        <p class="n2-sub-title">
          {{ translateTitle("安全、快捷、多样性") }}
        </p>
        <div class="n2c-items">
          <div class="n2c-item">
            <div class="item-new" v-if="true"></div>
            <div class="item-img-outer">
              <img src="../../assets/img_n/theme/item_1.png" class="item-img" />
            </div>
            <p class="item-title">{{ translateTitle("小游戏") }}</p>
            <p class="item-sub-title">
              {{ translateTitle("全新玩法，公平公开公正") }}
            </p>
            <!-- <p class="item-more">{{ translateTitle("查看更多") }} →</p> -->
          </div>
          <div class="n2c-item">
            <div class="item-new" v-if="false"></div>
            <div class="item-img-outer">
              <img src="../../assets/img_n/theme/item_2.png" class="item-img" />
            </div>
            <p class="item-title">{{ translateTitle("金融理财") }}</p>
            <p class="item-sub-title">
              {{ translateTitle("期限灵活、收益稳定、无风险") }}
            </p>
            <!-- <p class="item-more">{{ translateTitle("查看更多") }} →</p> -->
          </div>
          <div class="n2c-item">
            <div class="item-new" v-if="false"></div>
            <div class="item-img-outer">
              <img src="../../assets/img_n/theme/item_3.png" class="item-img" />
            </div>
            <p class="item-title">{{ translateTitle("股票") }}</p>
            <p class="item-sub-title">
              {{ translateTitle("美/港股无需开户，立即持仓") }}
            </p>
            <!-- <p class="item-more">{{ translateTitle("查看更多") }} →</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="home-content-n-3">
      <div class="n3-content">
        <div class="n3-left">
          <p class="n3l-title">
            {{ translateTitle("随时随地开启交易") }}
          </p>
          <p class="n3l-sub-title">
            {{ translateTitle("全面支持iOS、Android系统。") }}
          </p>
          <div class="n3l-item">
            <img class="item-img" src="../../assets/img_n/theme/ok_small.png" />
            <p class="item-text">
              {{ translateTitle("交易期货：U本位合约") }}
            </p>
          </div>
          <div class="n3l-item">
            <img class="item-img" src="../../assets/img_n/theme/ok_small.png" />
            <p class="item-text">
              {{
                translateTitle(
                  "交易现货：投资比特币（BTC）、以太坊（ETH）、莱特币（LTC）等"
                )
              }}
            </p>
          </div>
          <div class="n3l-item">
            <img class="item-img" src="../../assets/img_n/theme/ok_small.png" />
            <p class="item-text">
              {{ translateTitle("跟单：跟随精英交易员并复制他们的交易") }}
            </p>
          </div>
          <div class="n3l-item">
            <img class="item-img" src="../../assets/img_n/theme/ok_small.png" />
            <p class="item-text">
              {{ translateTitle("使用灵活储蓄产品赚取收益") }}
            </p>
          </div>
          <div class="n3l-item">
            <img class="item-img" src="../../assets/img_n/theme/ok_small.png" />
            <p class="item-text">……</p>
          </div>

          <div class="download-content">
            <div class="download-item">
              <a :href="android_url" target="_blank" rel="noopener noreferrer">
                <img src="../../assets/img_n/theme/google-down.png" alt="" />
              </a>
            </div>
            <div class="download-item">
              <a :href="appstore_url" target="_blank" rel="noopener noreferrer">
                <img src="../../assets/img_n/theme/app-down.png" alt="" />
              </a>
            </div>
            <div
              class="download-item qr-code"
              @mouseenter="onShowDownLoad"
              @mouseleave="onHiddenDownLoad"
            >
              <img src="../../assets/img/home/down-btn.png" alt="" />
              <div v-show="showDownLoadImg" class="download-code">
                <img class="qr_code_canvas" :src="appDownLoadImg" alt="" />
                <!-- <div class="scren-code">{{ translateTitle("扫码下载") }}</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="n3-right">
          <img
            src="../../assets/img_n/theme/web_app_interface.png"
            class="n3r-img"
          />
        </div>
      </div>
    </div>
    <!-- <ChatWedgetHx ref="ChatWedgetHx"></ChatWedgetHx> -->
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import Socket from "@/assets/js/socket.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import Cookies from "js-cookie";
import { apiGetBannerList, apiGetMainV2 } from "@/model/home/index";
import { mapActions, mapGetters } from "vuex";
import { apiWsUrl, apiGetSpotList } from "@/model/exchange/index";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import routeSplicing from "@/assets/js/routeSplicing";
import globalFun from "@/assets/js/globalFun.js";
// import ChatWedgetHx from "../customer-service/chatWedget-hx.vue";
var vm = null;
import {
  VUE_APP_ID,
  VUE_CHANNEL_ID,
  VUE_UNION_STATION,
} from "@/assets/js/stationSetting";
export default {
  mixins: [commonMixin],
  components: {
    swiper,
    swiperSlide,
    // ChatWedgetHx,
    NoData,
  },

  data() {
    return {
      detailsUrl: "",
      systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      // realIndex:0,
      isHuiXin: false,
      is8V: false,
      isUeasy: false,
      isS6: false,
      is5A: false,
      isSpex: false,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      showBanner: false,
      android_url: "",
      appstore_url: "",
      currentVideo:
        "https://static.topcreditbank.com/video/about/topcredit-cn.mp4",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",
      listType: [
        { text: "热币榜", val: 0 },
        // { text: "新币榜", val: 1 },
        // { text: "成交额榜", val: 2 },
        // { text: "热门合约榜", val: 3 },
        // { text: "日涨幅榜", val: 4 },
      ],
      active: 0,
      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        // on: {
        //   click: function() {
        //     // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
        //     // console.log(this); // -> Swiper
        //     // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
        //     const realIndex = this.realIndex;
        //     vm.handleClickSlide(realIndex);
        //   }
        // },
        on: {
          click: function (e) {
            window.open(e.target.dataset.href);
          },
        },

        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,

        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        autoplayDisableOnInteraction: false,
        // //循环
        loop: true,
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },
      oldImgList: [
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-1.png"),
            img_url_en: require("../../assets/img/home/banner-1.png"),
            img_url_ja: require("../../assets/img/home/banner-1.png"),
            img_url_ko: require("../../assets/img/home/banner-1.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-1.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-1.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-2.png"),
            img_url_en: require("../../assets/img/home/banner-2.png"),
            img_url_ja: require("../../assets/img/home/banner-2.png"),
            img_url_ko: require("../../assets/img/home/banner-2.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-2.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-2.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-3.png"),
            img_url_en: require("../../assets/img/home/banner-3.png"),
            img_url_ja: require("../../assets/img/home/banner-3.png"),
            img_url_ko: require("../../assets/img/home/banner-3.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-3.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-3.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-4.png"),
            img_url_en: require("../../assets/img/home/banner-4.png"),
            img_url_ja: require("../../assets/img/home/banner-4.png"),
            img_url_ko: require("../../assets/img/home/banner-4.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-4.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-4.png"),
          },
          param: { url: "" },
        },
      ],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      tempMarkList: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      tableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      timer: null,
      allCountMoney: 1884351973,
      allCountMoneyStr: "1,884,351,973",
      globalFun,
    };
  },
  async created() {
    if (this.language == "zh_CN") {
      this.currentVideo =
        "https://static.topcreditbank.com/video/about/topcredit-cn.mp4";
    } else if (this.language == "zh_HK") {
      this.currentVideo =
        "https://static.topcreditbank.com/video/about/topcredit-tw.mp4";
    } else {
      this.currentVideo =
        "https://static.topcreditbank.com/video/about/topcredit-en.mp4";
    }
    if (VUE_UNION_STATION == "TopCredit") {
      this.isHuiXin = true;
      this.logoImg = require("../../assets/img/home/top-logo.png");
    } else if (VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.oldImgList.forEach((ele) => {
        for (const key in ele.language) {
          ele.language[key] = require("../../assets/img/home/banner-5.png");
        }
      });
      this.logoImg = require("../../assets/img/home/bijiu-logo.png");
    } else if (VUE_UNION_STATION == "EverTrust") {
      this.logoImg = require("../../assets/img/home/everTrust-logo-en.png");
    } else if (VUE_UNION_STATION == "100Ex") {
      this.logoImg = require("../../assets/img/logo_100.png");
    } else if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
      this.logoImg = require("../../assets/img/logo_8V.png");
    } else if (VUE_UNION_STATION == "UEEx") {
      this.isUeasy = true;
      this.logoImg = require("../../assets/img/logo_UEEx.png");
      let _that = this;
      setInterval(() => {
        _that.allCountMoney =
          _that.allCountMoney +
          parseInt(Math.random() * (2500 - 1500 + 1) + 1500, 10);

        _that.$nextTick(() => {
          _that.allCountMoneyStr = _that.globalFun.money_format(
            _that.allCountMoney
          );
        });
      }, 6000);
    } else if (VUE_UNION_STATION == "Ahex") {
      this.logoImg = require("../../assets/img/logo_Ahex.png");
    } else if (VUE_UNION_STATION == "5A") {
      this.is5A = true;
      this.logoImg = require("../../assets/img/logo_5A.png");
      //根据国际化变更title
      document.getElementsByTagName("title")[0].innerText = this.translateTitle(
        "交易比特币BTC、以太币ETH和altcoin | 加密货币交易平台 | 5A数字交易所"
      );
    } else if (VUE_UNION_STATION == "bitda") {
      this.logoImg = require("../../assets/img/logo_bitda.png");
    } else if (VUE_UNION_STATION == "S6") {
      this.isS6 = true;
      this.logoImg = require("../../assets/img/logo_S6.png");
    } else if (VUE_UNION_STATION == "easy") {
      this.logoImg = require("../../assets/img/logo_easy.png");
    } else if (VUE_UNION_STATION == "spex") {
      this.isSpex = true;
      this.logoImg = require("../../assets/img/logo_spex.png");
    } else if (VUE_CHANNEL_ID > 2000) {
      this.logImg = require("../../assets/img/logo_doge.png");
      this.slogan = "数字理财 赢得未来";
    } else {
      this.logoImg = require("../../assets/img/home/top-logo.png");
    }
    await this.getBannerData();

    await this.getExchangeCoinMap();

    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    // this.appDownLoadImg = VUE_APP_APP + "/Dw/AppDownload?app_id=" + VUE_APP_ID;
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
      this.detailsUrl =
        zendesk_notice.language[`articles_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
      this.detailsUrl = zendesk_notice.articles_url;
    }

    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.tableLoading = true;
      setTimeout(() => {
        this.initWS();
      }, 2000);
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.tableLoading = true;
      setTimeout(() => {
        this.initWS();
      }, 2000);
    }
    vm = this;
    // await this.getItemList();

    // setTimeout(() => {
    //   this.initWS();
    // }, 1000);
    this.getTradePairList();
    this.voucherInterval = setInterval(() => {
      this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },

  methods: {
    routeSplicing,
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "statistics-warning-row";
      } else {
        return "";
      }
    },
    onInviteClick() {
      if (this.userInfo) {
        this.$router.push(
          this.routeSplicing(this.language, "ucenter/spread-center")
        );
      } else {
        this.$router.push(this.routeSplicing(this.language, "login"));
      }
    },
    jumpToUrl(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    jumpToUrlV2(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    getMainV2() {
      let params = {
        channel: 0,
      };
      apiGetMainV2(params).then((res) => {
        alert(res.status);
        if (res.status == 200) {
          // console.log(res.data);
        }
      });
    },
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws_url;
        this.android_url = data.android_url;
        this.appstore_url = data.appstore_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      ); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      if (this.language == "zh_CN") {
        window.open(this.tipUrl);
      } else {
        window.open("https://medium.com/@Topcredit_International");
      }
    },
    onLookMarket() {
      this.$router.push(this.routeSplicing(this.language, "markets"));
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },

    async getBannerData() {
      const req = {
        type: 1,
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200) {
        let len = data.length;
        if (len <= 4) {
          data.forEach((ele, index) => {
            this.oldImgList[index] = ele;
          });
        } else {
          this.imgList = data;
        }
        this.showBanner = true;
        this.sideLen = len >= 4 ? 4 : len;
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },
    async getDeatilsData() {
      var that = this;
      // let helpLang =
      //   this.language == "zh_CN" || this.language == "zh_HK"
      //     ? "zh-CN"
      //     : this.language == "vi"
      //     ? "vi"
      //     : "en";
      let helpLang = ["zh_CN", "zh_HK", "vi", "th"].includes(this.language)
        ? this.language
        : "en";
      let topDomain = document.domain.split(".").slice(-2).join(".");
      if (topDomain.includes("localhost")) {
        topDomain = "4ezh8.com";
      }
      let protocol = location.protocol;
      let host = location.host;
      let jumpUrl =
        protocol +
        "//" +
        host +
        "/help-center/" +
        this.language +
        "?path=category&lang=" +
        helpLang +
        "&code=NOTICE%3ANews&showContent=true&articleId=";
      // let newsUrl = "https://hcapi." + topDomain + this.detailsUrl;
      let newsUrl = this.detailsUrl;
      axios
        .get(newsUrl)
        .then(function (response) {
          // console.log("response", response);
          // that.articlesList = response.data.articles;
          if (response.data.data.length >= 1) {
            that.firstArticles.html_url =
              jumpUrl + response.data.data[0]["attributes"]["uuid"];
            that.firstArticles.title =
              response.data.data[0]["attributes"]["title"];
          } else {
            that.firstArticles.html_url = "";
            that.firstArticles.title = "";
          }
          if (response.data.data.length >= 2) {
            that.secondArticles.html_url =
              jumpUrl + response.data.data[1]["attributes"]["uuid"];
            that.secondArticles.title =
              response.data.data[1]["attributes"]["title"];
          } else {
            that.secondArticles.html_url = "";
            that.secondArticles.title = "";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // async getDeatilsData() {
    //   var that = this;
    //   axios
    //     .get(this.detailsUrl)
    //     .then(function (response) {
    //       if (that.language == "zh_CN") {
    //         that.articlesList = response.data.articles;
    //         if (response.data.articles.length >= 1) {
    //           that.firstArticles = response.data.articles[0];
    //         } else {
    //           that.firstArticles.html_url = "";
    //           that.firstArticles.title = "";
    //         }
    //         if (response.data.articles.length >= 2) {
    //           that.secondArticles = response.data.articles[1];
    //         } else {
    //           that.secondArticles.html_url = "";
    //           that.secondArticles.title = "";
    //         }
    //       } else {
    //         that.firstArticles.title =
    //           "Topcredit International: The Next Generation All-in-One Asset Management Platform Accelerating Globalization";
    //         that.firstArticles.html_url =
    //           "https://medium.com/@Topcredit_International/topcredit-international-the-next-generation-all-in-one-asset-management-platform-accelerating-a3476147f4ee";
    //         that.secondArticles.html_url =
    //           "https://medium.com/@Topcredit_International/topcredit-ints-3rd-anniversary-celebration-totally-20-000-in-usdt-for-grabs-b6e3ff8bb00a";
    //         that.secondArticles.title =
    //           "Topcredit Int’s 3rd Anniversary Celebration: Totally $20,000 in USDT For Grabs!";
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    getTradePairList() {
      apiGetSpotList().then((res) => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach((ele) => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                this.tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (
                  this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
                ) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          let tempList = this.tempMarkList.filter((ele) => {
            return ele.from_symbol != undefined && ele.web_recommend == 1;
          });
          this.tableDataComputed = tempList.sort(
            this.compareOrderBy("order_by")
          );
        }
      });
    },
    initWS() {
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "market",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      setTimeout(() => {}, 1200);
      // this.getSubData();
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.market") {
        this.tableData = data.data;

        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach((ele) => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        let tempList = this.tableData.filter((ele) => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });
        this.tableDataComputed = tempList.sort(this.compareOrderBy("order_by"));
      }
    },
    compareOrderBy(property) {
      return function (a, b) {
        if (
          a &&
          b &&
          a["info"] &&
          b["info"] &&
          a["info"][property] &&
          b["info"][property]
        ) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
  },
};
</script>
<style lang="scss">
.ar {
  .hx-home-page .el-table tbody .cell .coin-content {
    float: right;
    margin-right: 80px;
    .coin-img {
      margin-left: 10px;
    }
  }

  .hx-home-page .home-content-four .left-content .tips-details li i {
    margin-left: 6px;
    margin-right: 0;
  }
  .hx-home-page .home-content-four .left-content .download-content {
    direction: ltr;
  }
  .hx-home-page .home-content-four .left-content .download-code {
    height: 160px;
    .qr_code_canvas {
      margin-right: 0;
    }
  }
}
.zh_CN {
  .hx-home-page .ux-invite-content {
    background: url("../../assets/img/home/invite.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
}
.zh_HK {
  .hx-home-page .ux-invite-content {
    background: url("../../assets/img/home/invite.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
}
.hx-home-page {
  margin-top: -2px;
  // height: 2977px;
  display: block;
  [v-cloak] {
    display: none;
  }
  .ux-invite-content {
    cursor: pointer;
    width: 238px;
    height: 193px;
    background: url("../../assets/img/home/invite-en.png") 0 0 no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 45%;
    right: 30px;
  }
  .fiv-svg {
    position: relative;
    i {
      z-index: 900;
      position: absolute;
      font-size: 60px;
      top: -2px;
      left: 110px;
    }
  }

  // font-family: PingFangSC, BinancePlex, Arial, sans-serif !important;
  .is-resiger {
    height: 340px;
  }
  .no-resiger {
    height: 340px;
  }
  user-select: none;
  padding: 0;
  // min-height: 2000px;
  flex: 1;
  .swiper-img {
    width: 100%;
    height: 144px;
    border-radius: 6px;
  }

  .home-content-one {
    background: linear-gradient(96deg, #ffeed3 18.05%, #fff6e8 63.82%);
    min-height: 440px;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    .left-banner {
      min-height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .banner-content {
      width: 1200px;
      display: flex;
      min-height: inherit;
      align-items: center;
      justify-content: space-between;
      padding-right: 40px;
    }
    .first-lable {
      color: #202630;
      font-size: 38px;
      font-weight: bolder;
      text-align: center;
    }
    .second-lable {
      // color: #d9dad8;
      // font-size: 14px;
      text-align: left;
      padding-top: 15px;

      font-size: 16px;
      color: #202630;
    }
  }
  .tips-massage-content {
    margin-top: -20px;
    background: #f6f7f9;
    border-radius: 4px;
    .content {
      border-radius: 4px;

      width: 1200px;
      margin: 0 auto;
      padding: 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
    }
    .latest-news {
      width: 130.1px;
      height: 52px;
      line-height: 52px;

      text-align: center;
      position: relative;
      display: flex;
      align-items: center;

      .ln-text {
        top: 0;
        left: 0;
        width: 85%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--M--, #202630);
      }
    }
    .right-content-box {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -10px;
      background: #fff;
      clip-path: polygon(1.9% 0, 100% 0, 100% 100%, 0 100%);
      height: 52px;
      padding-left: 34px;
      .left {
        i {
          font-size: 16px;
          color: #080c34;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #c2c7d0;
      }

      .bottom {
        // min-width: 200px;
        // margin-left: 15px;

        text-align: left;
      }
      .article-title {
        color: #080c34 !important;
        min-width: 250px;
        display: inline-block;
        vertical-align: middle;
        max-width: 450px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      // .mxwid {
      //   max-width: 250px;
      // }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #c2c7d0;
      }
      .right {
        margin-left: auto;
        margin-right: 20px;
        cursor: pointer;
        i {
          font-size: 14px;
          color: #9aa1b1;
          margin-top: 2px;
          &:hover {
            color: #3a7dff;
          }
        }
        &:hover {
          color: #3a7dff;
          i {
            color: #3a7dff;
          }
        }
      }
    }
  }
  .home-content-two {
    min-height: 688px;
    background: #f8f9fd;
    padding-bottom: 30px;
    &.V2 {
      min-height: 588px;
    }
    .swiper-sider-content {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      position: static;
      .swiper-next {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        background-color: rgb(0, 0, 0, 0.4);
        right: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-prev {
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 99;
        width: 30px;
        height: 144px;
        top: 0px;
        outline: none;
        position: absolute;
        background-color: rgb(0, 0, 0, 0.4);
        left: 0px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        img {
          height: 17px;
          width: 10px;
        }
      }
      .swiper-content {
        display: flex;
        position: relative;
        align-items: center;
        width: 1200px;
        .button-prev {
          margin-right: 13px;
        }
        i {
          &:focus {
            border: 0px;
            outline: 0px;
          }
        }
        .iconfont {
          font-size: 28px;
          color: #747a7e;
          outline: none;
          cursor: pointer;
          border: 0px;
        }
        .swiper-container {
          width: 1200px;
          height: 144px;
          position: relative;
          border-radius: 6px;
          .swiper-wrapper {
            text-align: center !important;
          }

          img {
            cursor: pointer;
            // width: 282px;
            height: 144px;
            // opacity: 0.7;
            box-sizing: border-box;
            opacity: 1;
            &:hover {
              opacity: 1;
            }
          }
          .swiper-slide {
            margin-left: 0px !important;
          }
        }
      }
      .old-img {
        display: flex;
        width: 1200px;
        ul {
          // width: 1110px;
          &:last-child {
            li img {
              margin-right: 0;
            }
          }
          li {
            cursor: pointer;
            // width: 282px;
            // padding-right: 20px;
          }
        }

        img {
          width: 282px;
          height: 144px;
          margin-right: 24px;
          border-radius: 5px;
        }
      }
    }
    .swiper-pagination {
      position: relative !important;
      margin-top: 15px;
      span {
        margin: 0px 10px;
        // color: #9aa1b1;
      }
      .swiper-pagination-bullet-active {
        background-color: #c61b3d;
      }
    }

    .quotes-content {
      width: 1200px;
      // height: 572px;
      margin: 30px auto 0;
      // padding: 20px 20px 0px;
      padding: 10px 0px 0px;
      border-radius: 6px;
      // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      .left-symbol {
        display: inline-block;
        color: #354052;
        font-size: 14px;
        font-weight: 500;
      }
      .left-row {
        color: #354052;
        font-size: 14px;
        font-weight: 600;
      }

      .el-table {
        // min-height: 430px !important;
        tr {
          cursor: pointer;
          td:first-child {
            padding-left: 30px;
          }
          th:first-child {
            padding-left: 30px;
          }
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f1f5f6;
      }
      .more-info {
        display: flex;
        align-items: center;
        height: 72px;
        justify-content: center;

        text-align: center;
        font-size: 16px;
        .more-title {
          color: #131f30;
          width: 102px;
        }
        .more {
          cursor: pointer;
          &:hover {
            color: #3a7dff;
            span {
              color: #3a7dff;
            }
          }
        }

        span {
          color: #9aa1b1;
        }
        .icongengduo {
          font-size: 14px;
          // margin-top: 4px;
        }
      }

      .el-table {
        min-height: 300px;
        .el-icon-star-off {
          cursor: pointer;
        }
      }
      //表格变更开始
      .el-table__row > td {
        border: none;
      }
      .el-table::before {
        height: 0;
      }
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: 0px solid #ebeef5;
      }
      .el-table__row.statistics-warning-row {
        background: #f7f8f9;
      }
      .el-table_1_column_1 {
        padding-left: 30px;
      }
      .el-table_1_column_5 {
        padding-right: 30px;
      }
      .el-table th > .cell {
        padding-left: unset;
        padding-right: unset;
      }
      // .el-table__row.statistics-warning-white {
      //   background: #fff;
      // }
      //表格变更结束
      .list-type {
        display: flex;
        padding-left: 35px;
        margin-bottom: 20px;
        user-select: none;
        border-bottom: 1px solid #f5f5f7;
        .tab {
          position: relative;
          margin-right: 22px;
          color: #7f8490;
          line-height: 60px;
          cursor: pointer;
          span {
            font-size: 16px;
            font-weight: 400;
          }
          &:hover {
            color: #354052;
            &:after {
              content: "";
              height: 2px;
              width: 80%;
              background-color: #c61b3d;
            }
          }
          span {
            color: #131f30;
            font-size: 18px;
            // font-weight: 500;
          }
          &.active {
            span {
              color: #131f30;
              font-size: 18px;
              // font-weight: 500;
            }
            &:after {
              content: "";
              height: 3px;
              width: 20px;
              background-color: #c61b3d;
              border-radius: 1.5px;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .home-content-three {
    background: #f8f9fd;
    .content {
      background-color: #fff;
      border-radius: 4px;
      height: 140px;
      width: 1200px;
      position: relative;
      top: -40px;
      margin: 0 auto;
    }
    // background: url("../../assets/svg/bg.svg") 0 0 repeat;
    text-align: center;
    // overflow: hidden;
    // height: 350px;
    .volume-title {
      font-size: 32px;
      font-weight: 600;
      color: #202630;
      line-height: 45px;
      padding: 75px 0 12px;
      .theme-red {
        color: #c61b3d;
      }
    }

    .volume-sub-title {
      font-size: 14px;
      font-weight: 400;
      color: #202630;
      line-height: 30px;
    }

    .point {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 35px 30px 20px;

      &-item {
        display: flex;
        flex-direction: column;
        p {
          color: #202630;
          font-weight: 600;
          height: 50px;
          font-size: 30px;
        }

        span {
          color: #202630;
          line-height: 25px;
          font-size: 16px;
          font-weight: 500;
          // margin-top: 15px;
        }
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }
  .home-content-n-1 {
    display: flex;
    justify-content: center;
    .n1-content {
      width: 1200px;
      margin-top: 60px;
      display: flex;
      .n1-left {
        width: 818px;
        display: flex;
        flex-direction: column;
        .n1l-title {
          font-size: 34px;
          font-weight: 500;
          color: #202630;
        }
        .n1l-sub-title {
          margin-top: 14px;
          margin-bottom: 22.5px;
          font-size: 20px;
          // color: #74777c;
          color: #474d57;
        }
        .n1l-item {
          margin: 22.5px 0;
          display: flex;
          .item-img {
            width: 70px;
            height: 70px;
            margin-right: 32px;
          }
          .item-text {
            margin-top: 5px;
            .it-title {
              font-size: 22px;
              font-weight: 600;
              color: #202630;
            }
            .it-content {
              margin-top: 8px;
              font-size: 14px;
              // font-weight: 500;
              color: #474d57;
            }
          }
        }
        .begin {
          cursor: pointer;
          margin-top: 20px;
          max-width: 180px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          background: var(
            --88--M--,
            linear-gradient(270deg, #eeca7d 44%, #d5a35d 100%)
          );
          font-size: 16px;
          font-weight: 500;
          color: var(--M--, #202630);
        }
      }
      .n1-right {
        margin-top: 5px;
        .n1r-phone {
          width: 320px;
          height: 621px;
        }
      }
    }
  }
  .home-content-n-2 {
    display: flex;
    justify-content: center;
    .n2-content {
      width: 1200px;
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      .n2-title {
        font-size: 34px;
        font-weight: 500;
        color: #202630;
      }
      .n2-sub-title {
        margin-top: 14px;
        font-size: 20px;
        // color: #74777c;
        color: #474d57;
      }
      .n2c-items {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .n2c-item {
          width: 384px;
          height: 420px;
          border-radius: 8px;
          background-color: #f8f1e6;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          // cursor: pointer;
          &:hover {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
          }
          .item-new {
            background: url("../../assets/img_n/theme/new.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            width: 82px;
            height: 82px;
            top: 0;
            left: 0;
          }
          .item-img-outer {
            margin-top: 63px;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            .item-img {
              width: 88px;
              height: 88px;
            }
          }
          .item-title {
            margin-top: 25px;
            font-size: 22px;
            font-weight: 600;
            color: #202630;
          }
          .item-sub-title {
            margin-top: 10px;
            font-size: 14px;
            max-width: 340px;
            // font-weight: 500;
            color: #474d57;
          }
          .item-more {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 600;
            color: #202630;
          }
        }
      }
    }
  }
  .home-content-n-3 {
    margin-bottom: 73px;
    display: flex;
    justify-content: center;
    .n3-content {
      width: 1200px;
      margin-top: 60px;
      display: flex;
      .n3-left {
        width: 570px;
        display: flex;
        flex-direction: column;
        position: relative;
        .n3l-title {
          font-size: 34px;
          font-weight: 500;
          color: #202630;
        }
        .n3l-sub-title {
          margin-top: 11px;
          margin-bottom: 38px;
          font-size: 20px;
          // color: #74777c;
          color: #474d57;
        }
        .n3l-item {
          margin: 8px 0;
          display: flex;
          align-items: center;
          .item-img {
            width: 20px;
            height: 20px;
            margin-right: 15px;
          }
          .item-text {
            font-size: 14px;
            // font-weight: 500;
            color: #202630;
          }
        }
        .download-content {
          margin-top: 31px;
          display: flex;
          position: relative;
          .download-item {
            img {
              width: 150px;
              height: 50px;
              margin-right: 10px;
            }
          }
          .qr-code {
            margin-left: 6px;
            width: 55px;
            height: 50px;
            background-color: #1e2434;
            padding: 13px 15px 12px;
            border-radius: 3px;
            position: relative;
            img {
              cursor: pointer;
              text-align: center;
              width: 25px;
              height: 25px;
              color: #ffffff;
            }

            .download-code {
              position: absolute;
              top: -160px;
              right: -63px;
              box-sizing: border-box;
              width: 120px;
              height: 130px;
              border: solid 6px #e5e8eb;
              margin-top: 20px;
              background: #ffffff;
              padding: 10px;
              border-radius: 4px;
              z-index: 900;

              img {
                width: 100px;
                height: 100px;
              }
              .scren-code {
                width: 100%;
                margin-top: 5px;
                text-align: center;
                box-sizing: border-box;
                color: #1e2329;
                font-size: 14px;
              }
            }
          }
        }
      }
      .n3-right {
        .n3r-img {
          width: 630px;
          height: 420px;
        }
      }
    }
  }
  // .home-content-four {
  //   height: 600px;
  //   text-align: center;
  //   padding-bottom: 50px;

  //   .content-title {
  //     font-size: 36px;
  //     font-weight: 600;
  //     text-align: center;
  //     margin: 60px 0 62px;
  //     color: #354052;
  //   }
  //   .left-content {
  //     display: inline-block;
  //     text-align: left;
  //     vertical-align: middle;
  //     position: relative;
  //     width: 400px;
  //     // min-width: 420px;
  //     // max-width: 500px;
  //     margin-right: 80px;
  //     .tips-details {
  //       li {
  //         display: flex;
  //         i {
  //           margin-top: 3px;
  //           margin-right: 6px;
  //           color: #c61b3d;
  //         }
  //       }
  //     }

  //     .left-content-title {
  //       font-size: 18px;
  //       font-weight: 600;
  //       // width: 20px;
  //       color: #1f2533;
  //       margin-bottom: 25px;
  //     }
  //     li {
  //       margin-bottom: 14px;
  //       color: #354052;
  //     }
  //     img {
  //       vertical-align: middle;
  //       width: 16px;
  //       height: 16px;
  //       margin-right: 12px;
  //     }
  //     .download-content {
  //       margin-top: 40px;
  //       display: flex;
  //       position: relative;
  //       .download-item {
  //         img {
  //           width: 150px;
  //           height: 50px;
  //         }
  //       }
  //       .qr-code {
  //         width: 55px;
  //         height: 50px;
  //         background-color: #1e2434;
  //         padding: 13px 15px 12px;
  //         border-radius: 3px;

  //         img {
  //           cursor: pointer;
  //           text-align: center;
  //           width: 25px;
  //           height: 25px;
  //           color: #ffffff;
  //         }
  //       }
  //     }
  //     .download-code {
  //       position: absolute;
  //       right: 0px;
  //       box-sizing: border-box;
  //       width: 120px;
  //       height: 145px;
  //       border-color: #eaecef;
  //       margin-top: 10px;
  //       background: #ffffff;
  //       padding: 10px;
  //       border-radius: 4px;
  //       z-index: 900;

  //       img {
  //         width: 100px;
  //         height: 100px;
  //       }
  //       .scren-code {
  //         width: 100%;
  //         margin-top: 5px;
  //         text-align: center;
  //         box-sizing: border-box;
  //         color: #1e2329;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  //   .right-content {
  //     position: relative;
  //     display: inline-block;
  //     vertical-align: middle;
  //     width: 718px;
  //     height: 420px;
  //     .home-bg-logo {
  //       position: absolute;
  //       top: 21px;
  //       left: 6px;
  //       img {
  //         height: 16px;
  //       }
  //     }
  //   }
  // }
  // .home-content-five {
  //   height: 370px;

  //   .content-title {
  //     font-size: 36px;
  //     font-weight: 600;
  //     text-align: center;
  //     margin: 60px 0 62px;
  //     color: #1f2533;
  //   }
  //   .five-content {
  //     display: flex;
  //     justify-content: center;
  //     .details {
  //       display: flex;
  //       width: 1120px;
  //       justify-content: space-between;
  //     }
  //     .five-item {
  //       text-align: center;
  //       // width: 417px;

  //       img {
  //         width: 70px;
  //         height: 70px;
  //       }
  //       .p-content {
  //         font-size: 22px;
  //         font-weight: 600;
  //         text-align: center;
  //         color: #1f2533;
  //         margin-top: 15px;
  //         margin-bottom: 25px;
  //       }
  //       .s-content {
  //         margin-bottom: 77px;
  //         width: 285px;
  //         text-align: center;
  //         color: #354052;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  // }
  .home-content-six {
    height: 200px;
    text-align: center;
    // background-color: #17232c;
    background-color: #151617;
    .six-content {
      height: 200px;
      width: 100%;
      padding: 64px 0 87px;
    }
    .six-content-title {
      text-align: center;
      color: #ffffff;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .regsiter-content {
    margin: 30px 0px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      display: inline-block;
      width: 370px;
      height: 46px;
      margin: 0 10px 0 0;
      font-size: 16px;
      line-height: 46px;
      font-weight: 500;
      padding: 12px 0px 12px 17px;
      border-radius: 2px;
      background-color: #ffffff;
      &::placeholder {
        color: #dddee1;
      }
    }
    .goRegsiter {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      // background-color: #ff486d;
      background-image: linear-gradient(
        270deg,
        #eeca7d 44%,
        #d5a35d 100%
      ) !important;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: 130px;
      line-height: 46px;
      height: 46px;
      color: var(--M--, #202630) !important;
      box-sizing: border-box;
    }
    .trade-btn {
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      background: #c61b3d;
      text-align: center;
      font-size: 16px;
      display: inline-block;
      width: fit-content;
      padding: 0 30px;
      line-height: 46px;
      height: 46px;
      color: white;
      box-sizing: border-box;
    }
  }

  .el-table {
    padding: 0px;
    .el-table__header {
      thead {
        .cell {
          font-size: 14px;
          color: #7f8490;
        }
      }
    }
    tbody {
      .operate {
        min-width: 70px;
        height: 28px;
        padding: 4px 5px;
        text-align: center;
        border-radius: 2px;
        background: var(
          --88--M--,
          linear-gradient(270deg, #eeca7d 44%, #d5a35d 100%)
        ) !important;
        font-weight: 400 !important;
        font-size: 14px;
        box-sizing: border-box;
        border: none !important;
        span {
          font-weight: 400 !important;
          color: var(--M--, #202630);
        }
      }
      .mrket-img {
        height: 30px;
      }

      .cell {
        padding-left: unset;
        line-height: unset;
        .coin-content {
          // display: inline-block;
          display: flex;
          align-items: center;
          .coin-img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #03ad79;
        }
        .active {
          color: #c61b3d;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          text-align: left;
          cursor: pointer;
          margin-right: 10px;
        }
        p,
        span,
        code {
          color: #1f2533;
          // font-weight: 600;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #9aa1b1;
          font-size: 12px;
          margin-top: 2px;
        }
      }
    }

    .table-expand {
      min-height: 228px;
      padding: 10px;
      display: flex;
      cursor: auto;
      .expand-detail {
        flex: 1;
        display: inline-block;
        margin-right: 32px;
      }
      .el-form {
        display: inline-block;
        width: 300px;
        .el-form-item {
          margin-bottom: 0px;
          padding: 0 20px;
          .el-form-item__label {
            padding: 0;
          }
          &:nth-child(2n + 1) {
            background-color: #f5f5f7;
          }
        }
        .el-form-item__content {
          display: inline-block;
          float: right;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  .home-content-seven {
    background: #f8f9fd;
    padding-bottom: 120px;

    .content-title {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      color: #354052;
    }
    .volume-sub-title {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      color: #354052;
      margin-top: 10px;
      text-align: center;
      line-height: 30px;
      padding-bottom: 120px;
    }
    .mp4-content {
      position: relative;
      width: 1200px;
      height: 366px;
      margin: 0 auto;
      // background: url("../../assets/img/home/hx-mp4-bg.png") 0 0 repeat;
      .video-content {
        height: 506px;
        width: 900px;
        position: absolute;
        top: -70px;
        left: 150px;
        .video {
        }
      }
    }
  }
}
</style>
